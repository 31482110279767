body {
    position: relative;
    color: black;
    height: 100vh;
    background: white;
    font-family: sans-serif;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sf-main {
    text-align: center;
    border: solid 1px #ababab5e;
    padding: 2em;
    border-radius: 2em;
}

.logo {
    width: 300px;
    margin-bottom: 1em;
}

.sf-main-heading {
    font-weight: 100;
    margin-bottom: 1.2em;
    text-align: center;
}

.sf-main .grid .column {
    display: flex !important;
    justify-content: center;
}

.sf-footer {
    margin-top: 2em;
    text-align: center;
    font-size: smaller;
    color: grey;
}

.sf-footer>div {
    margin-top: 2em;
    font-size: smaller;
    color: #0000004d;
}

.sf-footer>div:hover {
    color: rgb(62, 62, 62);
}

.sf-blanket {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    z-index: 100;
}